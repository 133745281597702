import React, { FC } from 'react';

import { Col, Container, DangerouslySetInnerHtml, Layout, Row } from 'layout';

import { LdsPageProps } from './models';

const LdsPage: FC<LdsPageProps> = ({ pageContext }) => {
  const { body } = pageContext;
  const bodyClean = body.replace(/&nbsp;/gi, ' ').replace(/[ ]{2,}/g, ' ');

  return (
    <Layout>
      <section className="lds-page">
        <Container fluid>
          {body && bodyClean ? (
            <Row>
              <Col lg="8">
                <DangerouslySetInnerHtml html={bodyClean} />
              </Col>
            </Row>
          ) : null}
        </Container>
      </section>
    </Layout>
  );
};

export default LdsPage;
